import React, { useEffect, useState } from 'react';
import { Container, Table, Spinner, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { getOrders } from '../../services/orderService'; // Your service to fetch orders
import { FaPlus, FaMinus } from 'react-icons/fa';
import OrderDetailsTable from '../../components/OrderDetailsTable'; // This will be created next
import '../../styles.css';

const Orders = () => {
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedRows, setExpandedRows] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const ordersData = await getOrders(); // Fetch orders using the service
        setFilteredOrders(ordersData.orders);
      } catch (error) {
        console.error('Error fetching orders:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  const handleRowClick = (orderId) => {
    navigate(`/order-details?orderId=${orderId}`);
  };

  const toggleRowExpansion = (orderId) => {
    setExpandedRows((prevExpandedRows) =>
      prevExpandedRows.includes(orderId)
        ? prevExpandedRows.filter((id) => id !== orderId)
        : [...prevExpandedRows, orderId]
    );
  };

  const columns = [
    { key: 'orderId', label: 'Order ID' },
    { key: 'buyerCompanyName', label: 'Buyer Company Name' },
    { key: 'orderTotal', label: 'Order Total' },
    { key: 'orderStatus', label: 'Order Status' },
    { key: 'createdAt', label: 'Created At' },
    { key: 'updatedAt', label: 'Updated At' }
  ];

  if (loading) {
    return (
      <Container className="content d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  return (
    <Container className="content">
      <h1>Orders</h1>
      <Table striped bordered hover className="table-responsive" style={{ fontSize: '12px', margin: '0px', padding: '0px' }}>
        <thead>
          <tr>
            <th style={{ padding: '5px' }}></th> {/* Empty header for the [+] button */}
            {columns.map((col) => (
              <th key={col.key} style={{ whiteSpace: 'nowrap', padding: '5px' }}>{col.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {filteredOrders.map((order) => (
            <React.Fragment key={order.orderId}>
              <tr style={{ cursor: 'pointer' }} onClick={() => handleRowClick(order.orderId)}>
                <td style={{ whiteSpace: 'nowrap', padding: '0px' }} onClick={(e) => e.stopPropagation()}>
                  <Button variant="link" size="sm"
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleRowExpansion(order.orderId);
                    }}
                    style={{ paddingTop: 0, paddingBottom: 0, margin: 0 }}
                  >
                    {expandedRows.includes(order.orderId) ? <FaMinus /> : <FaPlus />}
                  </Button>
                </td>
                <td>{order.orderId}</td>
                <td>{order.orderDetails.buyerCompanyName}</td>
                <td>${order.orderSummary.orderTotal.toFixed(2)}</td>
                <td>{order.orderStatus}</td>
                <td>{new Date(order.createdAt).toLocaleString()}</td>
                <td>{new Date(order.updatedAt).toLocaleString()}</td>
              </tr>
              {expandedRows.includes(order.orderId) && (
                <tr>
                  <td colSpan="7" style={{ padding: '0px' }}>
                    <OrderDetailsTable order={order} />
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default Orders;

